import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import React, {useState, useEffect} from 'react'
import Slider from 'infinite-react-carousel'

import Icon from './icon/index'
import styles from './carousel.module.scss'
import './carousel.css'

const Carousel = (props) => {
  // Sets color
  const [ctaColor, setCtaColor] = useState(props.nodes[0].color)

  function handleChange (newi) {
    if (newi !== undefined) {
      setCtaColor(props.nodes[newi].color)
    }
  }

  const ctaStyle = {
    backgroundColor: ctaColor,
    transition: 'background-color 500ms'
  }

  const taStyle = {
    fill: ctaColor
  }

  const slideStyle = {
    minHeight: '20px'
  }

  return (
    <div className={styles.carousel} id={props.id}>
      <Slider
        autoplay
        autoplaySpeed={10000}
        duration={300}
        beforeChange={(oldidx, newidx) => handleChange(newidx)}
        afterChange={(idx) => handleChange(idx)}
        arrows={false}
        swipe={false}>
        {props.nodes &&
            props.nodes.map((node, i) => (
              <div className={styles.slide} key={i}>
                {node.image && node.image.asset && (
                  <div
                    style={{'backgroundImage': 'url(' + imageUrlFor(buildImageObj(node.image)).url() + ')'}}
                    className={styles.image}
                  />
                )}
              </div>
            ))
          }
      </Slider>
      <div className={styles.lockup}>
        <Icon symbol={'ta'} fill={ctaColor}></Icon>
        <Icon symbol={'tagline'}></Icon>
      </div>
      {props.showCTA &&
        <a href="mailto:info@sosolimited.com" target="_blank" className={styles.cta} style={ctaStyle}>
          <h3>Contact us to participate</h3>
          <Icon symbol='arrow' />
        </a>
      }
    </div>
  )
}

export default Carousel
