import React from 'react'

const Container = ({children, max, padding}) => {
  const conStyles = {
    boxSizing: 'border-box',
    maxWidth: max || 1024,
    padding: padding || '1.5em',
    margin: '0 auto'
  }
  return <div style={conStyles}>{children}</div>
}

export default Container
