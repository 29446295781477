import React from 'react'
import Icon from './icon/index'
import styles from './footer.module.scss'

const Footer = () => {

  return (
    <div className={styles.footer}>
      <div className={styles.by}>
        <p>A project initiated by Sosolimited.</p>
        <p>Contact us for more information</p>
      </div>
      <a href="https://www.sosolimited.com" target="_blank">
        <Icon symbol={'soso'}></Icon>
      </a>
      <div className={styles.info}>
        <p>127 Pearl St | Boston, MA 02110</p>
        <a href="mailto:info@sosolimited.com" target="_blank">info@sosolimited.com</a>
      </div>
    </div>
  )
}

export default Footer
